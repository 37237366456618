import Vue from "vue";
import Vuex from "vuex";
import apiClient from "../API/apiClient";

Vue.use(Vuex);

let store = new Vuex.Store({
  state: {
    currentOrdinal: 0,
    showEditForm: false,
    selectedEditObject: null,
    isLoading: false,
    totalCount: 100,
    options: {
      page: 1,
      itemsPerPage: 10,
      pageCount: 15,
    },
    searchTerm: "",
    selectedConfiguration: "",
    lastDispached: "",
    errorMessage: "",
    showErrorMessage: false,
    selectedInstitution: null,
    selectedCollectionProtocol: null,
    selectedCollectionProtocolabc: null,
    selectedCollectionProtocolParticipant: null,
    tests: [],
    panels: [],
    panelTests: [],
    testAcceptedValues: [],
    testResultRanges: [],
    buildings: [],
    devices: [],
    floors: [],
    positions: [],
    rooms: [],
    shelfs: [],
    wings: [],
    departments: [],
    ethnicities: [],
    genders: [],
    institutions: [],
    institutionSites: [],
    labelingSchemas: [],
    orderCommentTypes: [],
    maritalStatuses: [],
    patientTypes: [],
    positionLabelingOptions: [],
    priorities: [],
    races: [],
    specimenTypeCategories: [],
    specimenTypes: [],
    unitOfMeasurements: [],
    unitOfMeasurementTypes: [],
    vitalStatuses: [],
    patients: [],
    anatomicSites: [],
    collectionProtocols: [],
    pathologicalStatuses: [],
    specimenCollectionProcedures: [],
    collectionProtocolEventUnitOfMeasurements: [],
    collectionProtocolEvents: [],
    collectionProtocolConsents: [],
    collectionProtocolSites: [],
    collectionProtocolParticipants: [],
    collectionProtocolForms: [],
    collectionProtocolCollectedSamples: [],
    collectionProtocolFormFields: [],
    collectionProtocolEventSpecimenRequirements: [],
    collectionProtocolParticipantConsents: [],
    submittedCollectionProtocolForms: [],
    selectedCollectionProtocolEvent: null,
    limsOrders: [],
    orderCommentTypes: [],
    storageContainers: [],
    storageContainerBoxs: [],
    storageContainerBoxPositions: [],
    storageContainerBoxPositionOccupancies: [],
    storageContainerDrawers: [],
    storageContainerRacks: [],
    storageContainerShelfs: [],
    selectedAccount: null,
    collectionProtocolShipments: [],
    subSampleLineages: [],
    collectionProtocolId: null,
    selectedCollectionProtocolForm: null,
    accounts: [],
    phoneNumberTypeCategories: [],
    phoneNumberTypes: [],
  },
  mutations: {
    setCurrentOrdinal(state, ordinal) {
      state.currentOrdinal = ordinal;
    },
    setSearchTerm(state, searchTerm) {
      state.searchTerm = searchTerm;
    },
    setTotalCount(state, totalCount) {
      state.totalCount = totalCount;
    },
    setOptions(state, options) {
      state.options = options;
    },
    setIsLoading(state, payload) {
      state.isLoading = payload;
    },
    setShowErrorMessage(state, payload) {
      state.showErrorMessage = payload;
      if (!payload) {
        state.errorMessage = "";
      }
    },
    setSelectedConfiguration(state, payload) {
      state.selectedConfiguration = payload;
    },
    setErrorMessage(state, payload) {
      state.errorMessage = payload;
    },
    toggleEditView(state) {
      state.showEditForm = !state.showEditForm;
    },
    setSelectedEditObject(state, payload) {
      state.selectedEditObject = payload;
    },
    setSelectedLimsOrders(state, limsOrders) {
      console.log("setSelectedLimsOrders", limsOrders);
      state.limsOrders = limsOrders;
    },
    setSelectedInstitution(state, institution) {
      state.selectedInstitution = institution;
    },
    setAccounts(state, accounts) {
      state.accounts = accounts;
    },
    setOrderCommentTypes(state, orderCommentTypes) {
      state.orderCommentTypes = orderCommentTypes;
    },
    setCollectionProtocolShipments(state, collectionProtocolShipments) {
      state.collectionProtocolShipments = collectionProtocolShipments;
    },
    setSelectedCollectionProtocol(state, collectionProtocol) {
      state.selectedCollectionProtocol = collectionProtocol;
    },
    setSelectedCollectionProtocolabc(state, collectionProtocolabc) {
      state.selectedCollectionProtocolabc = collectionProtocolabc;
    },
    setSelectedCollectionProtocolParticipant(
      state,
      collectionProtocolParticipant
    ) {
      state.selectedCollectionProtocolParticipant =
        collectionProtocolParticipant;
    },
    setSelectedCollectionProtocolForm(state, collectionProtocolForm) {
      state.selectedcollectionProtocolForm = collectionProtocolForm;
      // console.log("setted collectionProtocolForm::", state.selectedcollectionProtocolForm);
    },
    setCollectionProtocolEventSpecimenRequirements(
      state,
      collectionProtocolEventSpecimenRequirements
    ) {
      state.collectionProtocolEventSpecimenRequirements =
        collectionProtocolEventSpecimenRequirements;
    },
    setCollectionProtocolParticipantConsents(
      state,
      collectionProtocolParticipantConsents
    ) {
      state.collectionProtocolParticipantConsents =
        collectionProtocolParticipantConsents;
    },
    setTests(state, tests) {
      state.tests = tests;
    },

    setPanels(state, panels) {
      state.panels = panels;
    },

    setPanelTests(state, panelTests) {
      state.panelTests = panelTests;
    },

    setTestAcceptedValues(state, testAcceptedValues) {
      state.testAcceptedValues = testAcceptedValues;
    },

    setTestResultRanges(state, testResultRanges) {
      state.testResultRanges = testResultRanges;
    },

    setBuildings(state, buildings) {
      state.buildings = buildings;
    },

    setDevices(state, devices) {
      state.devices = devices;
    },

    setFloors(state, floors) {
      state.floors = floors;
    },

    setPositions(state, positions) {
      state.positions = positions;
    },

    setRooms(state, rooms) {
      state.rooms = rooms;
    },

    setShelfs(state, shelfs) {
      state.shelfs = shelfs;
    },

    setWings(state, wings) {
      state.wings = wings;
    },

    setDepartments(state, departments) {
      state.departments = departments;
    },

    setEthnicities(state, ethnicities) {
      state.ethnicities = ethnicities;
    },

    setSubSampleLineages(state, subSampleLineages) {
      state.subSampleLineages = subSampleLineages;
    },

    setGenders(state, genders) {
      state.genders = genders;
    },

    setInstitutions(state, institutions) {
      state.institutions = institutions;
    },

    setInstitutionSites(state, institutionSites) {
      state.institutionSites = institutionSites;
    },

    setLabelingSchemas(state, labelingSchemas) {
      state.labelingSchemas = labelingSchemas;
    },
    setOrderCommentTypes(state, orderCommentTypes) {
      state.orderCommentTypes = orderCommentTypes;
    },

    setMaritalStatuses(state, maritalStatuses) {
      state.maritalStatuses = maritalStatuses;
    },

    setPatientTypes(state, patientTypes) {
      state.patientTypes = patientTypes;
    },

    setPositionLabelingOptions(state, positionLabelingOptions) {
      state.positionLabelingOptions = positionLabelingOptions;
    },

    setPriorities(state, priorities) {
      state.priorities = priorities;
    },

    setRaces(state, races) {
      state.races = races;
    },

    setSpecimenTypeCategories(state, specimenTypeCategories) {
      state.specimenTypeCategories = specimenTypeCategories;
    },

    setPhoneNumberTypeCategories(state, phoneNumberTypeCategories) {
      state.phoneNumberTypeCategories = phoneNumberTypeCategories;
    },

    setPhoneNumberTypes(state, phoneNumberTypes) {
      state.phoneNumberTypes = phoneNumberTypes;
    },

    setSpecimenTypes(state, specimenTypes) {
      state.specimenTypes = specimenTypes;
    },

    setUnitOfMeasurements(state, unitOfMeasurements) {
      state.unitOfMeasurements = unitOfMeasurements;
    },

    setUnitOfMeasurementTypes(state, unitOfMeasurementTypes) {
      state.unitOfMeasurementTypes = unitOfMeasurementTypes;
    },

    setVitalStatuses(state, vitalStatuses) {
      state.vitalStatuses = vitalStatuses;
    },

    setPatients(state, patients) {
      state.patients = patients;
    },

    setAnatomicSites(state, anatomicSites) {
      state.anatomicSites = anatomicSites;
    },

    setCollectionProtocols(state, collectionProtocols) {
      state.collectionProtocols = collectionProtocols;
    },

    setPathologicalStatuses(state, pathologicalStatuses) {
      state.pathologicalStatuses = pathologicalStatuses;
    },

    setSpecimenCollectionProcedures(state, specimenCollectionProcedures) {
      state.specimenCollectionProcedures = specimenCollectionProcedures;
    },

    setCollectionProtocolEventUnitOfMeasurements(
      state,
      collectionProtocolEventUnitOfMeasurements
    ) {
      state.collectionProtocolEventUnitOfMeasurements =
        collectionProtocolEventUnitOfMeasurements;
    },
    setCollectionProtocolEvents(state, collectionProtocolEvents) {
      state.collectionProtocolEvents = collectionProtocolEvents;
    },
    setCollectionProtocolConsents(state, collectionProtocolConsents) {
      state.collectionProtocolConsents = collectionProtocolConsents;
    },
    setCollectionProtocolFormFields(state, collectionProtocolFormFields) {
      state.collectionProtocolFormFields = collectionProtocolFormFields;
    },
    setCollectionProtocolSites(state, collectionProtocolSites) {
      state.collectionProtocolSites = collectionProtocolSites;
    },
    setCollectionProtocolCollectedSamples(
      state,
      collectionProtocolCollectedSamples
    ) {
      state.collectionProtocolCollectedSamples =
        collectionProtocolCollectedSamples;
    },
    setCollectionProtocolParticipants(state, collectionProtocolParticipants) {
      state.collectionProtocolParticipants = collectionProtocolParticipants;
    },
    setCollectionProtocolForms(state, collectionProtocolForms) {
      state.collectionProtocolForms = collectionProtocolForms;
    },
    setSelectedCollectionProtocolEvent(state, id) {
      state.collectionProtocolEvents.forEach((collectionProtocolEvent) => {
        if (collectionProtocolEvent.id == id) {
          collectionProtocolEvent.selected = true;
          state.selectedCollectionProtocolEvent = collectionProtocolEvent;
        } else {
          collectionProtocolEvent.selected = false;
        }
      });
    },
    setSubmittedCollectionProtocolForms(
      state,
      submittedCollectionProtocolForms
    ) {
      state.submittedCollectionProtocolForms = submittedCollectionProtocolForms;
    },
    setStorageContainers(state, storageContainers) {
      state.storageContainers = storageContainers;
    },

    setStorageContainerBoxs(state, storageContainerBoxs) {
      state.storageContainerBoxs = storageContainerBoxs;
    },

    setStorageContainerBoxPositions(state, storageContainerBoxPositions) {
      state.storageContainerBoxPositions = storageContainerBoxPositions;
    },

    setStorageContainerBoxPositionOccupancies(
      state,
      storageContainerBoxPositionOccupancies
    ) {
      state.storageContainerBoxPositionOccupancies =
        storageContainerBoxPositionOccupancies;
    },

    setStorageContainerDrawers(state, storageContainerDrawers) {
      state.storageContainerDrawers = storageContainerDrawers;
    },

    setStorageContainerRacks(state, storageContainerRacks) {
      state.storageContainerRacks = storageContainerRacks;
    },

    setStorageContainerShelfs(state, storageContainerShelfs) {
      state.storageContainerShelfs = storageContainerShelfs;
    },
    setSelectedAccount(state, account) {
      state.selectedAccount = account;
    },
  },
  actions: {
    setCurrentInstitution({ commit, state }, payload) {
      commit("setSelectedInstitution", payload);
      if (!!state.collectionProtocolId) {
        apiClient
          .call(
            "GET",
            "institutions/" +
              state.selectedInstitution.id +
              "/collectionProtocols/" +
              state.collectionProtocolId,
            null
          )
          .then((response) => {
            // console.log(response);
            commit("setSelectedCollectionProtocol", response);
          })
          .catch((err) => {
            state.isLoading = false;
            console.log(err);
          });
      }
    },
    getTests({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/tests?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setTests", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getPanels({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/panels?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setPanels", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getPanelTests({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/panelTests?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          console.log(response);
          commit("setPanelTests", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getAnatomicSites({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/anatomicSites?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          console.log(response);
          commit("setAnatomicSites", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },
    getCollectionProtocols({ commit, state }) {
      state.isLoading = true;
      commit("setCollectionProtocols", []);
      return apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/collectionProtocols?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          // console.log(response);
          commit("setCollectionProtocols", response);
          state.isLoading = false;
          return Promise.resolve(response);
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
          return Promise.reject(err);
        });
    },
    getPathologicalStatuses({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/pathologicalStatuses?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          console.log(response);
          commit("setPathologicalStatuses", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getSpecimenCollectionProcedures({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/specimenCollectionProcedures?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          console.log(response);
          commit("setSpecimenCollectionProcedures", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getOrderedTests({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/orderedTests?IsEnabled=true",
          null
        )
        .then((response) => {
          console.log(response);
          commit("setOrderedTests", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getCollectionProtocolEventUnitOfMeasurements({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/collectionProtocolEventUnitOfMeasurements?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          console.log(response);
          commit("setCollectionProtocolEventUnitOfMeasurements", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },
    // getCollectionProtocolEventSpecimenRequirements({ commit, state }) {
    //   state.isLoading = true;
    //   // console.log("selectedCollectionProtocolEvent", state.selectedCollectionProtocolEvent.label);
    //   // console.log("institutions/" + state.selectedInstitution.id + "/collectionProtocols/" + state.selectedCollectionProtocol.id + "/collectionProtocolEvents/" + state.selectedCollectionProtocolEvent.id + "/collectionProtocolEventSpecimenRequirements");
    //   apiClient
    //     .call("GET", "institutions/" + state.selectedInstitution.id + "/collectionProtocols/" + state.selectedCollectionProtocol.id + "/collectionProtocolEvents/" + state.selectedCollectionProtocolEvent.id + "/collectionProtocolEventSpecimenRequirements", null)
    //     .then((response) => {
    //       console.log("CollectionProtocolEventSpecimenRequirements", response);
    //       commit("setCollectionProtocolEventSpecimenRequirements", response);
    //       state.isLoading = false;
    //     })
    //     .catch((err) => {
    //       state.isLoading = false;
    //       console.log(err);
    //     });
    // },
    getCollectionProtocolEventSpecimenRequirements({
      commit,
      state,
      dispatch,
    }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/collectionProtocols/" +
            state.selectedCollectionProtocol.id +
            "/collectionProtocolEvents/" +
            state.selectedCollectionProtocolEvent.id +
            "/collectionProtocolEventSpecimenRequirements?IsEnabled=true",
          null
        )
        .then((collectionProtocolEventSpecimenRequirements) => {
          console.log(collectionProtocolEventSpecimenRequirements);
          var newCollectionProtocolEventSpecimenRequirements = [];
          collectionProtocolEventSpecimenRequirements.forEach(
            (collectionProtocolEventSpecimenRequirement) => {
              collectionProtocolEventSpecimenRequirement.addView = false;
              collectionProtocolEventSpecimenRequirement.children = [];
              console.log(
                ":::::",
                "institutions/" +
                  state.selectedInstitution.id +
                  "/collectionProtocols/" +
                  state.selectedCollectionProtocol.id +
                  "/collectionProtocolEvents/" +
                  state.selectedCollectionProtocolEvent.id +
                  "/collectionProtocolEventSpecimenRequirements/" +
                  collectionProtocolEventSpecimenRequirement.id +
                  "/collectionProtocolEventSpecimenRequirementItems"
              );
              apiClient
                .call(
                  "GET",
                  "institutions/" +
                    state.selectedInstitution.id +
                    "/collectionProtocols/" +
                    state.selectedCollectionProtocol.id +
                    "/collectionProtocolEvents/" +
                    state.selectedCollectionProtocolEvent.id +
                    "/collectionProtocolEventSpecimenRequirements/" +
                    collectionProtocolEventSpecimenRequirement.id +
                    "/collectionProtocolEventSpecimenRequirementItems?IsEnabled=true",
                  null
                )
                .then((collectionProtocolEventSpecimenRequirementItems) => {
                  console.log(
                    "collectionProtocolEventSpecimenRequirementItems",
                    collectionProtocolEventSpecimenRequirementItems
                  );
                  collectionProtocolEventSpecimenRequirementItems.forEach(
                    (collectionProtocolEventSpecimenRequirementItem) => {
                      collectionProtocolEventSpecimenRequirementItem.addView = false;
                      // if (collectionProtocolEventSpecimenRequirement.id == collectionProtocolEventSpecimenRequirementItem.parentCollectionProtocolEventSpecimenRequirementItemID) {
                      dispatch(
                        "getChildCollectionProtocolEventSpecimenRequirement",
                        collectionProtocolEventSpecimenRequirementItem
                      );
                      if (
                        collectionProtocolEventSpecimenRequirementItem.parentCollectionProtocolEventSpecimenRequirementItemID ==
                        null
                      ) {
                        collectionProtocolEventSpecimenRequirement.sampleID =
                          "";
                        collectionProtocolEventSpecimenRequirement.children.push(
                          collectionProtocolEventSpecimenRequirementItem
                        );
                      }

                      // }
                    }
                  );
                })
                .catch((err) => {
                  console.log(err);
                  state.isLoading = false;
                });
              collectionProtocolEventSpecimenRequirement.sampleID = "";
              newCollectionProtocolEventSpecimenRequirements.push(
                collectionProtocolEventSpecimenRequirement
              );
              console.log(
                "newCollectionProtocolEventSpecimenRequirements",
                newCollectionProtocolEventSpecimenRequirements
              );
            }
          );
          commit(
            "setCollectionProtocolEventSpecimenRequirements",
            newCollectionProtocolEventSpecimenRequirements
          );
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },
    getChildCollectionProtocolEventSpecimenRequirement(
      { commit, state, dispatch },
      collectionProtocolEventSpecimenRequirement
    ) {
      state.isLoading = true;
      collectionProtocolEventSpecimenRequirement.children = [];
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/collectionProtocols/" +
            state.selectedCollectionProtocol.id +
            "/collectionProtocolEvents/" +
            state.selectedCollectionProtocolEvent.id +
            "/collectionProtocolEventSpecimenRequirements/" +
            collectionProtocolEventSpecimenRequirement.id +
            "/collectionProtocolEventSpecimenRequirementItems?IsEnabled=true",
          null
        )
        .then((collectionProtocolEventSpecimenRequirementItems) => {
          // console.log(collectionProtocolEventSpecimenRequirementItems);
          collectionProtocolEventSpecimenRequirementItems.forEach(
            (collectionProtocolEventSpecimenRequirementItem) => {
              collectionProtocolEventSpecimenRequirementItem.addView = false;
              if (
                collectionProtocolEventSpecimenRequirement.id ==
                collectionProtocolEventSpecimenRequirementItem.parentCollectionProtocolEventSpecimenRequirementItemID
              ) {
                dispatch(
                  "getChildCollectionProtocolEventSpecimenRequirement",
                  collectionProtocolEventSpecimenRequirementItem
                );
                collectionProtocolEventSpecimenRequirement.sampleID = "";
                collectionProtocolEventSpecimenRequirement.children.push(
                  collectionProtocolEventSpecimenRequirementItem
                );
              }
              state.isLoading = false;
            }
          );
        })
        .catch((err) => {
          console.log(err);
          state.isLoading = false;
        });
    },
    getCollectionProtocolParticipantConsents({ commit, state }) {
      state.isLoading = true;
      console.log(
        ":::::::-------------------------::::::: selectedCollectionProtocolParticipant",
        state.selectedCollectionProtocolParticipant
      );
      // console.log("institutions/" + state.selectedInstitution.id + "/collectionProtocols/" + state.selectedCollectionProtocol.id + "/collectionProtocolParticipants/" + state.selectedCollectionProtocolParticipant.id + "/collectionProtocolParticipantConsents");
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/collectionProtocols/" +
            state.selectedCollectionProtocol.id +
            "/collectionProtocolParticipantConsents?IsEnabled=true&PatientId=" +
            state.selectedCollectionProtocolParticipant.patientID,
          null
        )
        .then((response) => {
          console.log("getCollectionProtocolParticipantConsents::::", response);
          // "/collectionProtocolParticipants/" + state.selectedCollectionProtocolParticipant.id +
          commit("setCollectionProtocolParticipantConsents", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },
    getSubmittedCollectionProtocolForms({ commit, state }) {
      state.isLoading = true;
      console.log(
        "selectedCollectionProtocolParticipant",
        state.selectedCollectionProtocolParticipant.label
      );
      console.log(
        "SubmittedCollectionProtocolForm endpoint::",
        "institutions/" +
          state.selectedInstitution.id +
          "/submittedCollectionProtocolForms?IsEnabled=true&PatientID=" +
          state.selectedCollectionProtocolParticipant.patientID
      );
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/submittedCollectionProtocolForms?IsEnabled=true&PatientID=" +
            state.selectedCollectionProtocolParticipant.patientID,
          null
        )
        .then((response) => {
          console.log("SubmittedCollectionProtocolForm:", response);

          // get submittedCollectionProtocolFormValues for each submittedCollectionProtocolForm and add to submittedCollectionProtocolForm
          var submittedCollectionProtocolForms = response;
          if (submittedCollectionProtocolForms != null) {
            var promises = [];

            submittedCollectionProtocolForms.forEach(
              (submittedCollectionProtocolForm) => {
                promises.push(
                  apiClient
                    .call(
                      "GET",
                      "institutions/" +
                        state.selectedInstitution.id +
                        "/submittedCollectionProtocolForms/" +
                        submittedCollectionProtocolForm.id +
                        "/submittedCollectionProtocolFormValues?IsEnabled=true",
                      null
                    )
                    .then((submittedCollectionProtocolFormValues) => {
                      submittedCollectionProtocolForm.submittedCollectionProtocolFormValues =
                        submittedCollectionProtocolFormValues;
                    })
                );

                // .then((submittedCollectionProtocolFormValues) => {
                //   submittedCollectionProtocolForm.submittedCollectionProtocolFormValues = submittedCollectionProtocolFormValues;
                // })
                // .catch((err) => {
                //   console.log(err);
                //   state.isLoading = false;
                // });
              }
            );

            Promise.all(promises)
              .then(() => {
                console.log(
                  "submittedCollectionProtocolForms",
                  submittedCollectionProtocolForms
                );
                commit(
                  "setSubmittedCollectionProtocolForms",
                  submittedCollectionProtocolForms
                );
                state.isLoading = false;
              })
              .catch((err) => {
                console.log(err);
                state.isLoading = false;
              });
          }
          //   + "/collectionProtocols/" +
          // state.selectedCollectionProtocol.id + "/collectionProtocolParticipants/" +
          // state.selectedCollectionProtocolParticipant.id
          // console.log(response);
          // commit("setSubmittedCollectionProtocolForms", response);
          // state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },
    getSubmittedCollectionProtocolFormsPerCollectionProtocolFormID(
      { commit, state },
      collectionProtocolFormID,
      EnabledStartDate = null,
      EnabledEndDate = null
    ) {
      state.isLoading = true;
      console.log(
        "EnabledStartDate",
        EnabledStartDate,
        "EnabledEndDate",
        EnabledEndDate
      );
      // console.log(
      //   "selectedCollectionProtocolParticipant",
      //   state.selectedCollectionProtocolParticipant.label
      // );
      // console.log(
      //   "SubmittedCollectionProtocolForm endpoint::",
      //   "institutions/" +
      //     state.selectedInstitution.id +
      //     "/submittedCollectionProtocolForms?IsEnabled=true&PageSize=10000000&CollectionProtocolFormID=" +
      //     collectionProtocolFormID
      // );
      return apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/submittedCollectionProtocolForms?IsEnabled=true&CollectionProtocolFormID=" +
            collectionProtocolFormID +
            "&PageSize=10000000" +
            (EnabledStartDate ? `&EnabledStartDate=${EnabledStartDate}` : "") +
            (EnabledEndDate ? `&EnabledEndDate=${EnabledEndDate}` : ""),
          null
        )
        .then((response) => {
          // console.log("SubmittedCollectionProtocolForm:", response);

          // get submittedCollectionProtocolFormValues for each submittedCollectionProtocolForm and add to submittedCollectionProtocolForm
          var submittedCollectionProtocolForms = response;
          if (submittedCollectionProtocolForms != null) {
            var promises = [];

            var submittedCollectionProtocolFormIds =
              submittedCollectionProtocolForms.map(
                (submittedCollectionProtocolForm) => {
                  return submittedCollectionProtocolForm.id;
                }
              );

            return apiClient
              .call("POST", "submittedCollectionProtocolFormValues/search", {
                SubmittedCollectionProtocolFormIds:
                  submittedCollectionProtocolFormIds,
                PageSize: 10000000,
              })
              .then((response) => {
                console.log(
                  "SubmittedCollectionProtocolFormValues: >> ",
                  response
                );

                submittedCollectionProtocolForms.forEach(
                  (submittedCollectionProtocolForm) => {
                    submittedCollectionProtocolForm.submittedCollectionProtocolFormValues =
                      response.filter(
                        (submittedCollectionProtocolFormValue) => {
                          return (
                            submittedCollectionProtocolFormValue.submittedCollectionProtocolFormID ==
                            submittedCollectionProtocolForm.id
                          );
                        }
                      );
                  }
                );
                state.isLoading = false;
                return Promise.resolve(submittedCollectionProtocolForms);
              })
              .catch((err) => {
                console.log(err);
                state.isLoading = false;
                return Promise.reject(err);
              });

            submittedCollectionProtocolForms.forEach(
              (submittedCollectionProtocolForm) => {
                promises.push(
                  apiClient
                    .call(
                      "GET",
                      "institutions/" +
                        state.selectedInstitution.id +
                        "/submittedCollectionProtocolForms/" +
                        submittedCollectionProtocolForm.id +
                        "/submittedCollectionProtocolFormValues" +
                        "?IsEnabled=true&PageSize=10000000",
                      null
                    )
                    .then((submittedCollectionProtocolFormValues) => {
                      submittedCollectionProtocolForm.submittedCollectionProtocolFormValues =
                        submittedCollectionProtocolFormValues;
                    })
                );

                // .then((submittedCollectionProtocolFormValues) => {
                //   submittedCollectionProtocolForm.submittedCollectionProtocolFormValues = submittedCollectionProtocolFormValues;
                // })
                // .catch((err) => {
                //   console.log(err);
                //   state.isLoading = false;
                // });
              }
            );

            return Promise.all(promises)
              .then(() => {
                // console.log(
                //   "submittedCollectionProtocolForms",
                //   submittedCollectionProtocolForms
                // );
                commit(
                  "setSubmittedCollectionProtocolForms",
                  submittedCollectionProtocolForms
                );
                state.isLoading = false;
                return Promise.resolve(submittedCollectionProtocolForms);
              })
              .catch((err) => {
                console.log(err);
                state.isLoading = false;
                return Promise.reject(err);
              });
          }
          //   + "/collectionProtocols/" +
          // state.selectedCollectionProtocol.id + "/collectionProtocolParticipants/" +
          // state.selectedCollectionProtocolParticipant.id
          // console.log(response);
          // commit("setSubmittedCollectionProtocolForms", response);
          // state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },
    getSubmittedCollectionProtocolFormsPerQuery({ commit, state }, query) {
      state.isLoading = true;

      var collectionProtocolFormID = query.collectionProtocolFormID;
      var EnabledStartDate = query.EnabledStartDate;
      var EnabledEndDate = query.EnabledEndDate;

      console.log(
        "EnabledStartDate",
        EnabledStartDate,
        "EnabledEndDate",
        EnabledEndDate
      );
      // console.log(
      //   "selectedCollectionProtocolParticipant",
      //   state.selectedCollectionProtocolParticipant.label
      // );
      // console.log(
      //   "SubmittedCollectionProtocolForm endpoint::",
      //   "institutions/" +
      //     state.selectedInstitution.id +
      //     "/submittedCollectionProtocolForms?IsEnabled=true&PageSize=10000000&CollectionProtocolFormID=" +
      //     collectionProtocolFormID
      // );
      return apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/submittedCollectionProtocolForms?IsEnabled=true&CollectionProtocolFormID=" +
            collectionProtocolFormID +
            "&PageSize=10000000" +
            (EnabledStartDate ? `&EnabledStartDate=${EnabledStartDate}` : "") +
            (EnabledEndDate ? `&EnabledEndDate=${EnabledEndDate}` : ""),
          null
        )
        .then((response) => {
          // console.log("SubmittedCollectionProtocolForm:", response);

          // get submittedCollectionProtocolFormValues for each submittedCollectionProtocolForm and add to submittedCollectionProtocolForm
          var submittedCollectionProtocolForms = response;
          if (submittedCollectionProtocolForms != null) {
            var submittedCollectionProtocolFormIds =
              submittedCollectionProtocolForms.map(
                (submittedCollectionProtocolForm) => {
                  return submittedCollectionProtocolForm.id;
                }
              );

            return apiClient
              .call("POST", "submittedCollectionProtocolFormValues/search", {
                SubmittedCollectionProtocolFormIds:
                  submittedCollectionProtocolFormIds,
                PageSize: 10000000,
              })
              .then((response) => {
                console.log(
                  "SubmittedCollectionProtocolFormValues: >> ",
                  response
                );

                submittedCollectionProtocolForms.forEach(
                  (submittedCollectionProtocolForm) => {
                    submittedCollectionProtocolForm.submittedCollectionProtocolFormValues =
                      response.filter(
                        (submittedCollectionProtocolFormValue) => {
                          return (
                            submittedCollectionProtocolFormValue.submittedCollectionProtocolFormID ==
                            submittedCollectionProtocolForm.id
                          );
                        }
                      );
                  }
                );

                commit(
                  "setSubmittedCollectionProtocolForms",
                  submittedCollectionProtocolForms
                );

                state.isLoading = false;
                return Promise.resolve(submittedCollectionProtocolForms);
              })
              .catch((err) => {
                console.log(err);
                state.isLoading = false;
                return Promise.reject(err);
              });

            var promises = [];

            submittedCollectionProtocolForms.forEach(
              (submittedCollectionProtocolForm) => {
                promises.push(
                  apiClient
                    .call(
                      "GET",
                      "institutions/" +
                        state.selectedInstitution.id +
                        "/submittedCollectionProtocolForms/" +
                        submittedCollectionProtocolForm.id +
                        "/submittedCollectionProtocolFormValues" +
                        "?IsEnabled=true&PageSize=10000000",
                      null
                    )
                    .then((submittedCollectionProtocolFormValues) => {
                      submittedCollectionProtocolForm.submittedCollectionProtocolFormValues =
                        submittedCollectionProtocolFormValues;
                    })
                );

                // .then((submittedCollectionProtocolFormValues) => {
                //   submittedCollectionProtocolForm.submittedCollectionProtocolFormValues = submittedCollectionProtocolFormValues;
                // })
                // .catch((err) => {
                //   console.log(err);
                //   state.isLoading = false;
                // });
              }
            );

            return Promise.all(promises)
              .then(() => {
                // console.log(
                //   "submittedCollectionProtocolForms",
                //   submittedCollectionProtocolForms
                // );
                commit(
                  "setSubmittedCollectionProtocolForms",
                  submittedCollectionProtocolForms
                );
                state.isLoading = false;
                return Promise.resolve(submittedCollectionProtocolForms);
              })
              .catch((err) => {
                console.log(err);
                state.isLoading = false;
                return Promise.reject(err);
              });
          }
          //   + "/collectionProtocols/" +
          // state.selectedCollectionProtocol.id + "/collectionProtocolParticipants/" +
          // state.selectedCollectionProtocolParticipant.id
          // console.log(response);
          // commit("setSubmittedCollectionProtocolForms", response);
          // state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },
    getCollectionProtocolEvents({ commit, state, dispatch }) {
      // console.log("currentCollectionProtocol", state.selectedCollectionProtocol);
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/collectionProtocols/" +
            state.selectedCollectionProtocol.id +
            "/collectionProtocolEvents?IsEnabled=true",
          null
        )
        .then((response) => {
          response.forEach((element) => {
            element.selected = false;
          });
          response[0].selected = true;
          state.selectedCollectionProtocolEvent = response[0];
          commit("setCollectionProtocolEvents", response);
          dispatch("getCollectionProtocolEventSpecimenRequirements");
          // console.log(state.collectionProtocolEvents);
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },
    getCollectionProtocolConsents({ commit, state, dispatch }) {
      state.isLoading = true;
      commit("setCollectionProtocolConsents", []);
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/collectionProtocols/" +
            state.selectedCollectionProtocol.id +
            "/collectionProtocolConsents?IsEnabled=true",
          null
        )
        .then((response) => {
          console.log("collection-protocol consents", response);
          commit("setCollectionProtocolConsents", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getCollectionProtocolSites({ commit, state, dispatch }) {
      state.isLoading = true;
      commit("setCollectionProtocolSites", []);
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/collectionProtocols/" +
            state.selectedCollectionProtocol.id +
            "/collectionProtocolSites?IsEnabled=true",
          null
        )
        .then((response) => {
          commit("setCollectionProtocolSites", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },
    getCollectionProtocolFormFields(
      { commit, state, dispatch },
      id,
      fieldsOnly = false
    ) {
      if (!id) {
        commit("setCollectionProtocolFormFields", []);
      }
      return apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/collectionProtocols/" +
            state.selectedCollectionProtocol.id +
            "/collectionProtocolForms/" +
            id +
            "/collectionProtocolFormFields?IsEnabled=true&PageSize=100000",
          null
        )
        .then((response) => {
          if (fieldsOnly) {
            return Promise.resolve(response);
          }

          response.forEach((collectionProtocolFormField) => {
            collectionProtocolFormField.value = "";
            // console.log("collectionProtocolFormField", collectionProtocolFormField);
            collectionProtocolFormField.Options = [];
            apiClient
              .call(
                "GET",
                "institutions/" +
                  state.selectedInstitution.id +
                  "/collectionProtocols/" +
                  state.selectedCollectionProtocol.id +
                  "/collectionProtocolForms/" +
                  id +
                  "/collectionProtocolFormFields/" +
                  collectionProtocolFormField.id +
                  "/collectionProtocolFormFieldOptions?IsEnabled=true",
                null
              )
              .then((formFieldOptions) => {
                // console.log("institutions/" + state.selectedInstitution.id + "/collectionProtocols/" + state.selectedCollectionProtocol.id + "/collectionProtocolForms/" + id + "/collectionProtocolFormFields/" + collectionProtocolFormField.id + "/collectionProtocolFormFieldOptions");
                // console.log(formFieldOptions);
                formFieldOptions.forEach((formFieldOption) => {
                  if (formFieldOption.isEnabled) {
                    collectionProtocolFormField.Options.push(formFieldOption);
                  }
                  // collectionProtocolFormField.Options.push(formFieldOption);
                  // console.log("formFieldOption", option);
                });
              })
              .catch((err) => {
                state.isLoading = false;
                console.log(err);
              });
          });
          // console.log("CollectionProtocolFormFields", response);
          commit("setCollectionProtocolFormFields", response);
          state.isLoading = false;
          return Promise.resolve(response);
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
          return Promise.reject(err);
        });
    },
    getCollectionProtocolCollectedSamples({ commit, state, dispatch }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/collectionProtocols/" +
            state.selectedCollectionProtocol.id +
            "/collectedSamples?IsEnabled=true&IsSubsample=false",
          null
        )
        .then((collectionProtocolCollectedSamples) => {
          // console.log(collectionProtocolCollectedSamples);
          var newCollectionProtocolCollectedSamples = [];
          collectionProtocolCollectedSamples.forEach(
            (collectionProtocolCollectedSample) => {
              collectionProtocolCollectedSample.addView = false;
              collectionProtocolCollectedSample.children = [];
              apiClient
                .call(
                  "GET",
                  "institutions/" +
                    state.selectedInstitution.id +
                    "/collectionProtocols/" +
                    state.selectedCollectionProtocol.id +
                    "/collectedSamples?IsEnabled=true&IsSubsample=true",
                  null
                )
                .then((collectedSamples) => {
                  // console.log(collectedSamples);
                  collectedSamples.forEach((collectedSample) => {
                    collectedSample.addView = false;
                    if (
                      collectionProtocolCollectedSample.id ==
                      collectedSample.parentSampleID
                    ) {
                      dispatch(
                        "getChildCollectionProtocolCollectedSample",
                        collectedSample
                      );
                      collectionProtocolCollectedSample.children.push(
                        collectedSample
                      );
                    }
                  });
                })
                .catch((err) => {
                  console.log(err);
                  state.isLoading = false;
                });
              newCollectionProtocolCollectedSamples.push(
                collectionProtocolCollectedSample
              );
              // console.log("newCollectionProtocolCollectedSamples", newCollectionProtocolCollectedSamples);
            }
          );
          commit(
            "setCollectionProtocolCollectedSamples?IsEnabled=true",
            newCollectionProtocolCollectedSamples
          );
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },
    getChildCollectionProtocolCollectedSample(
      { commit, state, dispatch },
      collectionProtocolCollectedSample
    ) {
      // state.isLoading = true;
      collectionProtocolCollectedSample.children = [];
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/collectionProtocols/" +
            state.selectedCollectionProtocol.id +
            "/collectedSamples?IsEnabled=true&IsSubsample=true",
          null
        )
        .then((collectedSamples) => {
          // console.log(collectedSamples);
          collectedSamples.forEach((collectedSample) => {
            collectedSample.addView = false;
            if (
              collectionProtocolCollectedSample.id ==
              collectedSample.parentSampleID
            ) {
              dispatch(
                "getChildCollectionProtocolCollectedSample",
                collectedSample
              );
              collectionProtocolCollectedSample.children.push(collectedSample);
            }
            // state.isLoading = false;
          });
        })
        .catch((err) => {
          console.log(err);
          state.isLoading = false;
        });
    },
    getCollectionProtocolParticipants({ commit, state, dispatch }) {
      state.isLoading = true;
      commit("setCollectionProtocolParticipants", []);
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/collectionProtocols/" +
            state.selectedCollectionProtocol.id +
            "/collectionProtocolParticipants?IsEnabled=true&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setCollectionProtocolParticipants", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },
    getCollectionProtocolForms({ commit, state, dispatch }) {
      state.isLoading = true;
      commit("setCollectionProtocolForms", []);
      return apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/collectionProtocols/" +
            state.selectedCollectionProtocol.id +
            "/collectionProtocolForms?IsEnabled=true",
          null
        )
        .then((response) => {
          commit("setCollectionProtocolForms", response);
          state.isLoading = false;
          return Promise.resolve(response);
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
          return Promise.reject(err);
        });
    },
    getCollectionProtocolFormByFormId({ commit, state, dispatch }, formId) {
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/collectionProtocols/" +
            state.selectedCollectionProtocol.id +
            "/collectionProtocolForms/" +
            formId,
          null
        )
        .then((response) => {
          commit("setSelectedCollectionProtocolForm", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },
    getTestAcceptedValues({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/testAcceptedValues?IsEnabled=true",
          null
        )
        .then((response) => {
          commit("setTestAcceptedValues", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getAllTestAcceptedValues({ commit, state, dispatch }) {
      dispatch("getTests");
      if (state.tests) {
        var tempTestAcceptedValues = [];
        state.tests.forEach((test) => {
          // console.log(test.title);
          console.log(
            "https://127.0.0.1:6001/api/institutions/" +
              state.selectedInstitution.id +
              "/tests/" +
              test.id +
              "/testAcceptedValues"
          );
          apiClient
            .call(
              "GET",
              "institutions/" +
                state.selectedInstitution.id +
                "/tests/" +
                test.id +
                "/testAcceptedValues?IsEnabled=true",
              null
            )
            .then((response) => {
              response.forEach((item) => {
                item.test = test;
                tempTestAcceptedValues.push(item);
                // console.log(item);
              });
            })
            .catch((err) => {
              state.isLoading = false;
              console.log(err);
            });
        });
        console.log("|______________________________|");
        console.log(tempTestAcceptedValues);
        commit("setTestAcceptedValues", tempTestAcceptedValues);
      }
    },

    getTestResultRanges({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/testResultRanges?IsEnabled=true",
          null
        )
        .then((response) => {
          commit("setTestResultRanges", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getAllTestResultRanges({ commit, state, dispatch }) {
      dispatch("getTests");
      if (state.tests) {
        var tempTestResultRanges = [];
        state.tests.forEach((test) => {
          console.log(test.title);
          console.log(
            "https://127.0.0.1:6001/api/institutions/" +
              state.selectedInstitution.id +
              "/tests/" +
              test.id +
              "/testResultRanges"
          );
          apiClient
            .call(
              "GET",
              "institutions/" +
                state.selectedInstitution.id +
                "/tests/" +
                test.id +
                "/testResultRanges?IsEnabled=true&SearchTerm=" +
                state.searchTerm +
                "&PageNumber=" +
                state.options.page +
                "&PageSize=" +
                state.options.itemsPerPage,
              null
            )
            .then((response) => {
              response.forEach((item) => {
                item.test = test;
                tempTestResultRanges.push(item);
                // console.log(item);
              });
            })
            .catch((err) => {
              state.isLoading = false;
              console.log(err);
            });
        });
        console.log("|______________________________|");
        console.log(tempTestResultRanges);
        commit("setTestResultRanges", tempTestResultRanges);
      }
    },

    getDepartments({ commit, state }) {
      state.isLoading = true;
      commit("setDepartments", []);
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/departments?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setDepartments", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getEthnicities({ commit, state }) {
      state.isLoading = true;
      commit("setEthnicities", []);
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/ethnicities?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setEthnicities", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getGenders({ commit, state }) {
      state.isLoading = true;
      // console.log("getting genders...");
      return apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/genders?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setGenders", response);
          state.isLoading = false;
          return Promise.resolve(response);
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
          return Promise.reject(err);
        });
    },

    getSubSampleLineages({ commit, state }) {
      state.isLoading = true;
      // console.log("getting subSampleLineages...");
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/subSampleLineages?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setSubSampleLineages", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getInstitutions({ commit, state }) {
      state.isLoading = true;

      apiClient
        .call("GET", "institutions", null)
        .then((response) => {
          commit("setInstitutions", response);
          // commit("setSelectedInstitution", response[0]);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getInstitutionSites({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/institutionSites?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setInstitutionSites", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getAllInstitutionSites({ commit, state, dispatch }) {
      dispatch("getInstitutions");
      commit("setInstitutionSites", []);
      if (state.institutions) {
        var tempInstitutionSites = [];
        var count = 0;
        state.institutions.forEach((institution) => {
          console.log(institution.title);
          apiClient
            .call(
              "GET",
              "institutions/" +
                institution.id +
                "/institutionSites?IsEnabled=true&SearchTerm=" +
                state.searchTerm +
                "&PageNumber=" +
                state.options.page +
                "&PageSize=" +
                state.options.itemsPerPage,
              null
            )
            .then((response) => {
              response.forEach((item) => {
                state.institutionSites.push(item);
                console.log(item);
              });
              count++;
            })
            .catch((err) => {
              state.isLoading = false;
              console.log(err);
            });
        });
        console.log("|______________________________|");
        while (count == state.institutions.length) {
          console.log(tempInstitutionSites);
          commit("setInstitutionSites", tempInstitutionSites);
          count++;
        }
      }
    },

    // getAllInstitutionSites({ commit, state, dispatch }) {
    //   dispatch("getInstitutions");
    //   state.isLoading = true;
    //   console.log("institutions", state.institutions);
    //   commit("setInstitutionSites", []);
    //   // console.log("institutions", institutions);
    //   // var allInstitutionSites = [];
    //   // state.institutions.forEach((institution) => {
    //   //   apiClient
    //   //     .call("GET", "institutions/" + institution.id + "/institutionSites?IsEnabled=true&SearchTerm="+state.searchTerm+"&PageNumber=" + state.options.page + "&PageSize=" + state.options.itemsPerPage, null)
    //   //     .then((institutionSites) => {
    //   //       institutionSites.forEach((institutionSite) => {
    //   //         console.log("::::::::::::::::::: institutionSite: " + institutionSite.title);
    //   //         // state.institutionSites.push(institutionSite);
    //   //         state.institutionSites.push(institutionSite);
    //   //       });
    //   //       state.isLoading = false;
    //   //     })
    //   //     .catch((err) => {
    //   //       console.log(err);
    //   //     });
    //   // });

    //   console.log("allInstitutionSites", allInstitutionSites);
    //   commit("setInstitutionSites", allInstitutionSites);

    //   // apiClient
    //   //   .call("GET", "institutions", null)
    //   //   .then((institutions) => {
    //   //     console.log("institutions", institutions);
    //   //     institutions.forEach((institution) => {
    //   //       console.log("institution sites:::: ", getInstitutionSites(institution));
    //   //     });
    //   //     state.isLoading = false;
    //   //   })
    //   //   .catch((err) => {
    //   //     state.isLoading = false;
    //   //     console.log(err);
    //   //   });

    //   // apiClient
    //   //   .call("GET", "institutions", null)
    //   //   .then((institutions) => {
    //   //     institutions.forEach((institution) => {
    //   //       console.log("::::::::::::::::::: institution: " + institution.title);
    //   //       apiClient
    //   //         .call("GET", "institutions/" + institution.id + "/institutionSites?IsEnabled=true&SearchTerm="+state.searchTerm+"&PageNumber=" + state.options.page + "&PageSize=" + state.options.itemsPerPage, null)
    //   //         .then((institutionSites) => {
    //   //           institutionSites.forEach((institutionSite) => {
    //   //             console.log("::::::::::::::::::: institutionSite: " + institutionSite.title);
    //   //             // state.institutionSites.push(institutionSite);
    //   //             allInstitutionSites.push(institutionSite);
    //   //           });
    //   //           state.isLoading = false;
    //   //         })
    //   //         .catch((err) => {
    //   //           console.log(err);
    //   //         });
    //   //     });
    //   //     commit("setInstitutionSites", allInstitutionSites);
    //   //   })
    //   //   .catch((err) => {
    //   //     state.isLoading = false;
    //   //     console.log(err);
    //   //   });
    // },

    getInstitutionSitesByInstitutionId({ commit, state }, id) {
      apiClient
        .call("GET", "institutions/" + id + "/institutionSites", null)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getLabelingSchemas({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/labelingSchemas?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setLabelingSchemas", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getOrderCommentTypes({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/LIMSOrderCommentTypes?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setOrderCommentTypes", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getAccounts({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "users?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setAccounts", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getCollectionProtocolShipments({ commit, state }, isSent) {
      state.isLoading = true;
      commit("setCollectionProtocolShipments", []);
      console.log(
        "GET",
        "institutions/" +
          state.selectedInstitution.id +
          "/collectedSampleShipments?IsEnabled=true&" +
          (isSent ? "SendingInstitutionID" : "ReceivingInstitutionID") +
          "=" +
          state.selectedInstitution.id
      );
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/collectedSampleShipments?IsEnabled=true&" +
            (isSent ? "SendingInstitutionID" : "ReceivingInstitutionID") +
            "=" +
            state.selectedInstitution.id,
          null
        )
        .then((response) => {
          commit("setCollectionProtocolShipments", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getMaritalStatuses({ commit, state }) {
      state.isLoading = true;
      return apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/maritalStatuses?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setMaritalStatuses", response);
          state.isLoading = false;
          return Promise.resolve(response);
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
          return Promise.reject(err);
        });
    },

    getPatientTypes({ commit, state }) {
      state.isLoading = true;
      // console.log("getPatientTypes...");
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/patientTypes?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setPatientTypes", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getPositionLabelingOptions({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/positionLabelingOptions?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setPositionLabelingOptions", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getPriorities({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/priorities?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setPriorities", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getRaces({ commit, state }) {
      state.isLoading = true;
      commit("setRaces", []);
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/races?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setRaces", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getSpecimenTypeCategories({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/specimenTypeCategories?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setSpecimenTypeCategories", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getSpecimenTypes({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/specimenTypes?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setSpecimenTypes", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getAllSpecimenTypes({ commit, state, dispatch }) {
      dispatch("getSpecimenTypeCategories");
      if (state.specimenTypeCategories) {
        var tempSpecimenTypes = [];
        state.specimenTypeCategories.forEach((specimenTypeCategory) => {
          console.log(specimenTypeCategory.title);
          console.log(
            "https://127.0.0.1:6001/api/institutions/" +
              state.selectedInstitution.id +
              "/specimenTypeCategories/" +
              specimenTypeCategory.id +
              "/specimenTypes"
          );
          apiClient
            .call(
              "GET",
              "institutions/" +
                state.selectedInstitution.id +
                "/specimenTypeCategories/" +
                specimenTypeCategory.id +
                "/specimenTypes?IsEnabled=true&SearchTerm=" +
                state.searchTerm +
                "&PageNumber=" +
                state.options.page +
                "&PageSize=" +
                state.options.itemsPerPage,
              null
            )
            .then((response) => {
              response.forEach((item) => {
                tempSpecimenTypes.push(item);
                console.log(item);
              });
            })
            .catch((err) => {
              state.isLoading = false;
              console.log(err);
            });
        });
        console.log("|______________________________|");
        console.log(tempSpecimenTypes);
        commit("setSpecimenTypes", tempSpecimenTypes);
      }
    },
    getPhoneNumberCategories({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/phoneNumberCategories?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setPhoneNumberCategories", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getPhoneNumberTypeCategories({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/phoneNumberTypeCategories?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setPhoneNumberTypeCategories", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getPhoneNumberTypesByPhoneNumberTypeCategory(
      { commit, state },
      phoneNumberTypeCategory
    ) {
      console.log(phoneNumberTypeCategory);
      if (!phoneNumberTypeCategory) {
        return;
      }
      console.log("getPhoneNumberTypesByPhoneNumberTypeCategory:::::::::::");
      console.log("phoneNumberTypeCategory changed");
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/phoneNumberTypeCategories/" +
            phoneNumberTypeCategory.id +
            "/phoneNumberTypes?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setPhoneNumberTypes", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getAllUnitOfMeasurements({ commit, state, dispatch }) {
      dispatch("getUnitOfMeasurementTypes");
      if (state.unitOfMeasurementTypes) {
        var tempUnitOfMeasurements = [];
        state.unitOfMeasurementTypes.forEach((unitOfMeasurementType) => {
          // console.log(unitOfMeasurementType.title);
          // console.log("https://127.0.0.1:6001/api/institutions/" + state.selectedInstitution.id + "/unitOfMeasurementTypes/" + unitOfMeasurementType.id + "/unitOfMeasurements");
          apiClient
            .call(
              "GET",
              "institutions/" +
                state.selectedInstitution.id +
                "/unitOfMeasurementTypes/" +
                unitOfMeasurementType.id +
                "/unitOfMeasurements?IsEnabled=true&SearchTerm=" +
                state.searchTerm +
                "&PageNumber=" +
                state.options.page +
                "&PageSize=" +
                state.options.itemsPerPage,
              null
            )
            .then((response) => {
              response.forEach((item) => {
                tempUnitOfMeasurements.push(item);
                // console.log(item);
              });
            })
            .catch((err) => {
              state.isLoading = false;
              console.log(err);
            });
        });
        console.log("|______________________________|");
        console.log(tempUnitOfMeasurements);
        commit("setUnitOfMeasurements", tempUnitOfMeasurements);
      }
    },
    getSpecimenTypesBySpecimenTypeCategory(
      { commit, state },
      specimenTypeCategory
    ) {
      console.log(specimenTypeCategory);
      if (!specimenTypeCategory) {
        return;
      }
      console.log("specimenTypeCategory changed");
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/specimenTypeCategories/" +
            specimenTypeCategory.id +
            "/specimenTypes?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setSpecimenTypes", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getUnitOfMeasurements({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/unitOfMeasurements?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setUnitOfMeasurements", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getUnitOfMeasurementTypes({ commit, state }) {
      state.isLoading = true;
      console.log("getting UnitOfMeasurementTypes");
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/unitOfMeasurementTypes?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setUnitOfMeasurementTypes", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getVitalStatuses({ commit, state }) {
      state.isLoading = true;
      return apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/vitalStatuses?IsEnabled=true&SearchTerm=" +
            state.searchTerm +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setVitalStatuses", response);
          state.isLoading = false;
          return Promise.resolve(response);
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
          return Promise.reject(err);
        });
    },

    getPatients({ commit, state }, search) {
      state.isLoading = true;
      if (search == undefined) {
        search = "";
      }
      commit("setPatients", []);
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/patients?IsEnabled=true&SearchTerm=" +
            search +
            "&PageNumber=" +
            state.options.page +
            "&PageSize=" +
            state.options.itemsPerPage,
          null
        )
        .then((response) => {
          commit("setPatients", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },
    getStorageContainers({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/storageContainers?IsEnabled=true",
          null
        )
        .then((response) => {
          commit("setStorageContainers", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getStorageContainerBoxs({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/storageContainerBoxs?IsEnabled=true",
          null
        )
        .then((response) => {
          commit("setStorageContainerBoxs", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getStorageContainerBoxPositions({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/storageContainerBoxPositions?IsEnabled=true",
          null
        )
        .then((response) => {
          commit("setStorageContainerBoxPositions", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getStorageContainerBoxPositionOccupancies({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/storageContainerBoxPositionOccupancies?IsEnabled=true",
          null
        )
        .then((response) => {
          commit("setStorageContainerBoxPositionOccupancies", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getStorageContainerDrawers({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/storageContainerDrawers?IsEnabled=true",
          null
        )
        .then((response) => {
          commit("setStorageContainerDrawers", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getStorageContainerRacks({ commit, state }) {
      state.isLoading = true;
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/storageContainers/" +
            shelfId +
            "/storageContainerShelfs/",
          null
        )
        .then((response) => {
          commit("setStorageContainerRacks", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },

    getStorageContainerShelfs({ commit, state }, shelfId) {
      console.log("getStorageContainerShelfs invoked::::::::::");
      apiClient
        .call(
          "GET",
          "institutions/" +
            state.selectedInstitution.id +
            "/storageContainers/" +
            shelfId +
            "/storageContainerShelfs?IsEnabled=true",
          null
        )
        .then((response) => {
          commit("setStorageContainerShelfs", response);
          state.isLoading = false;
        })
        .catch((err) => {
          state.isLoading = false;
          console.log(err);
        });
    },
  },
  modules: {},
  getters: {
    isLoading: (state) => {
      return state.isLoading;
    },
    errorMessage: (state) => {
      return state.errorMessage;
    },
    lastDispached: (state) => {
      return state.lastDispached;
    },
    // showErrorMessage: (state) => {
    //   return state.showErrorMessage;
    // },
    totalCount: (state) => {
      return state.totalCount;
    },
    showEditForm: (state) => {
      return state.showEditForm;
    },
    currentOrdinal: (state) => {
      return state.currentOrdinal;
    },
    selectedConfiguration: (state) => {
      return state.selectedConfiguration;
    },
    selectedEditObject: (state) => {
      return state.selectedEditObject;
    },
    currentInstitution: (state) => {
      return state.selectedInstitution ? state.selectedInstitution : "";
    },
    currentCollectionProtocol: (state) => {
      return state.selectedCollectionProtocol
        ? state.selectedCollectionProtocol
        : "";
    },
    currentCollectionProtocolabc: (state) => {
      return state.selectedCollectionProtocolabc
        ? state.selectedCollectionProtocolabc
        : "";
    },
    currentCollectionProtocolParticipant: (state) => {
      return state.selectedCollectionProtocolParticipant
        ? state.selectedCollectionProtocolParticipant
        : "";
    },
    anatomicSites: (state) => {
      return state.anatomicSites;
    },
    accounts: (state) => {
      return state.accounts;
    },
    phoneNumberTypes: (state) => {
      return state.phoneNumberTypes;
    },
    phoneNumberTypeCategories: (state) => {
      return state.phoneNumberTypeCategories;
    },
    collectionProtocols: (state) => {
      return state.collectionProtocols;
    },
    selectedCollectionProtocolEvent: (state) => {
      return state.selectedCollectionProtocolEvent;
    },
    currentCollectionProtocolForm: (state) => {
      return state.selectedCollectionProtocolForm
        ? state.selectedCollectionProtocolForm
        : "";
    },
    limsOrders: (state) => {
      return state.limsOrders;
    },

    pathologicalStatuses: (state) => {
      return state.pathologicalStatuses;
    },

    specimenCollectionProcedures: (state) => {
      return state.specimenCollectionProcedures;
    },

    collectionProtocolEventUnitOfMeasurements: (state) => {
      return state.collectionProtocolEventUnitOfMeasurements;
    },
    collectionProtocolEventSpecimenRequirements: (state) => {
      return state.collectionProtocolEventSpecimenRequirements;
    },
    collectionProtocolParticipantConsents: (state) => {
      return state.collectionProtocolParticipantConsents;
    },
    collectionProtocolEvents: (state) => {
      return state.collectionProtocolEvents;
    },
    collectionProtocolConsents: (state) => {
      return state.collectionProtocolConsents;
    },
    collectionProtocolSites: (state) => {
      return state.collectionProtocolSites;
    },
    collectionProtocolParticipants: (state) => {
      return state.collectionProtocolParticipants;
    },
    submittedCollectionProtocolForms: (state) => {
      return state.submittedCollectionProtocolForms;
    },
    collectionProtocolForms: (state) => {
      return state.collectionProtocolForms;
    },
    collectionProtocolCollectedSamples: (state) => {
      return state.collectionProtocolCollectedSamples;
    },
    collectionProtocolFormFields: (state) => {
      return state.collectionProtocolFormFields;
    },
    collectionProtocolShipments: (state) => {
      return state.collectionProtocolShipments;
    },
    tests: (state) => {
      return state.tests;
    },
    panels: (state) => {
      return state.panels;
    },
    panelTests: (state) => {
      return state.panelTests;
    },
    testAcceptedValues: (state) => {
      return state.testAcceptedValues;
    },
    testResultRanges: (state) => {
      return state.testResultRanges;
    },
    buildings: (state) => {
      return state.buildings;
    },
    devices: (state) => {
      return state.devices;
    },
    floors: (state) => {
      return state.floors;
    },
    positions: (state) => {
      return state.positions;
    },
    rooms: (state) => {
      return state.rooms;
    },
    shelfs: (state) => {
      return state.shelfs;
    },
    wings: (state) => {
      return state.wings;
    },
    departments: (state) => {
      return state.departments;
    },
    ethnicities: (state) => {
      return state.ethnicities;
    },
    genders: (state) => {
      return state.genders;
    },
    subSampleLineages: (state) => {
      return state.subSampleLineages;
    },
    institutions: (state) => {
      return state.institutions;
    },
    institutionSites: (state) => {
      return state.institutionSites;
    },
    labelingSchemas: (state) => {
      return state.labelingSchemas;
    },
    orderCommentTypes: (state) => {
      return state.orderCommentTypes;
    },
    maritalStatuses: (state) => {
      return state.maritalStatuses;
    },
    patientTypes: (state) => {
      return state.patientTypes;
    },
    positionLabelingOptions: (state) => {
      return state.positionLabelingOptions;
    },
    priorities: (state) => {
      return state.priorities;
    },
    races: (state) => {
      return state.races;
    },
    specimenTypeCategories: (state) => {
      return state.specimenTypeCategories;
    },
    specimenTypes: (state) => {
      return state.specimenTypes;
    },
    unitOfMeasurements: (state) => {
      return state.unitOfMeasurements;
    },
    unitOfMeasurementTypes: (state) => {
      return state.unitOfMeasurementTypes;
    },
    vitalStatuses: (state) => {
      return state.vitalStatuses;
    },
    patients: (state) => {
      return state.patients;
    },
    storageContainers: (state) => {
      return state.storageContainers;
    },

    storageContainerBoxs: (state) => {
      return state.storageContainerBoxs;
    },
    storageContainerBoxPositions: (state) => {
      return state.storageContainerBoxPositions;
    },
    storageContainerBoxPositionOccupancies: (state) => {
      return state.storageContainerBoxPositionOccupancies;
    },
    storageContainerDrawers: (state) => {
      return state.storageContainerDrawers;
    },
    storageContainerRacks: (state) => {
      return state.storageContainerRacks;
    },
    storageContainerShelfs: (state) => {
      return state.storageContainerShelfs;
    },
  },
});
export default store;
// store.subscribe((mutation, state) => {
//   console.log("mutation.type", mutation.type);
//   // state.lastDispached = mutation.type;
//   console.log(state.lastDispached);
// });

store.subscribeAction({
  before: (action, state) => {
    // console.log(`before action ${action.type}`);
  },
  after: (action, state) => {
    // console.log(`after action ${action.type}`);
    state.lastDispached = action.type;
    // console.log(state.lastDispached);
  },
});

// methods
function getInstitutionSites(institution) {
  apiClient
    .call("GET", "institutions/" + institution.id + "/institutionSites", null)
    .then((institutionSites) => {
      console.log("institutionSites", institutionSites);
      return institutionSites;
    })
    .catch((err) => {
      console.log(err);
    });
}
